import { PieChart } from "@mui/x-charts";
import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Card, ListGroup, ProgressBar } from "react-bootstrap";
import { createClient } from "@supabase/supabase-js";
import { getSuperBaseUrl, getSuperBaseKey } from "../Config";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import nodata from "../Assets/images/no_data.png";

const supabase = createClient(getSuperBaseUrl(), getSuperBaseKey());

const Leads = () => {
    const navigate = useNavigate();
    const [leads, setLeads] = useState<any[]>([]);
    const [leadNotContacted, setLeadNotContacted] = useState<any[]>([]);
    const [followupDelayed, setFollowupDelayed] = useState<any[]>([]);
    const [newLeads, setNewLeads] = useState<any[]>([]);
    const [followingUp, setFollowingUp] = useState<any[]>([]);
    const [contractSigned, setContractSigned] = useState<any[]>([]);
    const [declined, setDeclined] = useState<any[]>([]);
    const { isSignedIn, user, isLoaded } = useUser();

    const [dleads, setdLeads] = useState<any[]>([]);
    const [dleadNotContacted, setdLeadNotContacted] = useState<any[]>([]);
    const [dfollowupDelayed, setdFollowupDelayed] = useState<any[]>([]);
    const [dnewLeads, setdNewLeads] = useState<any[]>([]);
    const [dfollowingUp, setdFollowingUp] = useState<any[]>([]);
    const [dcontractSigned, setdContractSigned] = useState<any[]>([]);
    const [ddeclined, setdDeclined] = useState<any[]>([]);

    useEffect(() => {
        FetchData();
    }, []);

    useEffect(() => {
        supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                },
                (payload) => FetchData()
            )
            .subscribe();
    }, [])

    function isArray(value: unknown): value is any[] {
        return Array.isArray(value);
    }
    const FetchData = async () => {
        let query = supabase
            .from('leads')
            .select('*')
            .gte('created_at', new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString())
            .lte('created_at', new Date().toISOString());

        if (isLoaded && isSignedIn) {
            if (user?.publicMetadata.role === "top_management") {
                query = query.or(`top_level_userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
            } else if (user?.publicMetadata.role === "supervisor") {
                query = query.or(`supivisor_userName.ilike.%${user?.username}%,and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
            } else if (user?.publicMetadata.role === "saler") {
                query = query.or(`userName.eq.${user?.username},and(userName.is.null,organization_name.eq.${user?.publicMetadata.organization_name})`);
            } else {
                return;
            }


            const { data, error } = await query.order('id', { ascending: false });

            const filteredLeads = data;

            console.log(data);
            const projects: any[] = isArray(user?.publicMetadata.projects) ? user?.publicMetadata.projects : [];
            // const facebookEngagementLead = data?.filter(lead => {
            //     if (lead.lead_origin === "facebook_engagment" && projects.includes(lead.project) && user?.publicMetadata.organization_name === lead.organization_name) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            // });
            if (filteredLeads) {
                setLeads(filteredLeads);
                // facebookEngagementLead?.forEach((lead: any) => {
                //     filteredLeads.push(lead);
                // });
                filterLeads(filteredLeads);
            }
        }
    }

    const filterLeads = (leadsToFilter: any[]) => {
        setDeclined(leadsToFilter.filter(lead => lead.status === 'declined'));
        setContractSigned(leadsToFilter.filter(lead => lead.status === 'contract_signed'));
        setFollowupDelayed(leadsToFilter.filter(lead => {
            const dateCreated = new Date();
            const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
            const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
            return (differenceInHours + 2 < 0) && lead.status !== "contract_signed" && lead.status !== "declined";
        }));
        setNewLeads(leadsToFilter.filter(lead => {
            const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
            return (dateCreated / (1000 * 60 * 60) <= 8) && (lead.viewed === false);
        }));
        setFollowingUp(leadsToFilter.filter(lead => {
            const dateCreated = new Date();
            const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
            const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
            return lead.is_following_up === true && lead.status !== 'contract_signed' && lead.status !== "declined" && (differenceInHours + 2 >= 0)
        })); // has been removed && lead.status !== "lead_not_contacted"
        // setLeadNotContacted(leadsToFilter.filter(lead => lead.status === 'lead_not_contacted'));
        setLeadNotContacted(leadsToFilter.filter(lead => {
            const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
            return (dateCreated / (1000 * 60 * 60) > 8) && (lead.viewed === false);
        }));
        setdLeads(leadsToFilter.filter(lead => {
            const date = new Date(lead.created_at);
            if (date.getDate() === new Date().getDate()) {
                return true;
            } else {
                return false;
            }
        }))
        filterDailyLeads(dleads);
    };

    const filterDailyLeads = (leadsToFilter: any[]) => {
        setdDeclined(leadsToFilter.filter(lead => lead.status === 'declined'));
        setdContractSigned(leadsToFilter.filter(lead => lead.status === 'contract_signed'));
        setdFollowupDelayed(leadsToFilter.filter(lead => {
            const dateCreated = new Date();
            const dateFollowUp = lead.next_following_up_date ? new Date(lead.next_following_up_date) : dateCreated;
            const differenceInHours = (dateFollowUp.getTime() - dateCreated.getTime()) / (1000 * 60 * 60);
            return (differenceInHours + 2 < 0) && lead.status !== "contract_signed" && lead.status !== "declined";
        }));
        setdNewLeads(leadsToFilter.filter(lead => {
            const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
            return (dateCreated / (1000 * 60 * 60) <= 8) && (lead.viewed === false);
        }));
        setdFollowingUp(leadsToFilter.filter(lead => lead.is_following_up === true && lead.status !== "contract_signed" && lead.status !== "declined")); // has been removed && lead.status !== "lead_not_contacted"
        // setdLeadNotContacted(leadsToFilter.filter(lead => lead.status === 'lead_not_contacted'));
        setdLeadNotContacted(leadsToFilter.filter(lead => {
            const dateCreated = new Date().getTime() - new Date(lead.created_at).getTime();
            return (dateCreated / (1000 * 60 * 60) > 8) && (lead.viewed === false);
        }));
    };

    useMemo(() => { filterDailyLeads(dleads); }, [dleads]);

    return (
        <>
            <Container className='text-center' style={{ maxHeight: '100vh', overflowY: 'auto', scrollbarWidth: 'none' }}>
                <h5 className="my-2">Lead Summary - {user?.username}</h5>
                <Row fluid>
                    <Col>
                        <Card className="overflow-hidden my-2">
                            <Card.Body>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: newLeads.length, label: "new" },
                                                { id: 1, value: leadNotContacted.length, label: "not contacted" },
                                                { id: 2, value: followupDelayed.length, label: "delayed" },
                                                { id: 3, value: declined.length, label: "declined" },
                                                { id: 4, value: contractSigned.length, label: "converted" },
                                                { id: 5, value: followingUp.length, label: "following" },
                                            ],
                                        },
                                    ]}
                                    width={400}
                                    height={200}

                                />
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>Lead Status - Monthly Update</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="justify-content-evenly d-flex" style={{ cursor: 'pointer' }} onClick={() => { navigate(`/dashboard/notContact`) }}><span className="col-6">Leads Not Contacted</span> <span className="col-6">{leadNotContacted.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-evenly d-flex" style={{ color: '#961406', cursor: 'pointer' }} onClick={() => { navigate(`/dashboard/followUpDelayed`) }}><span className="col-6">Follow Up Delayed</span> <span className="col-6" style={{ color: '#961406' }}>{followupDelayed.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex" style={{ cursor: 'pointer' }}><span className="col-6" onClick={() => { navigate(`/dashboard/newLeads`) }}>New Leads</span> <span className="col-6">{newLeads.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex" style={{ cursor: 'pointer' }}><span className="col-6" onClick={() => { navigate(`/dashboard/followingUp`) }}>Following Up</span> <span className="col-6">{followingUp.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex" style={{ cursor: 'pointer' }}><span className="col-6" onClick={() => { navigate(`/dashboard/contractSigned`) }}>Converted</span> <span className="col-6">{contractSigned.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex" style={{ color: 'red', cursor: 'pointer' }} onClick={() => { navigate(`/dashboard/declined`) }}><span className="col-6">Declined</span> <span className="col-6" style={{ color: 'red' }}>{declined.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex" style={{ cursor: 'pointer' }}><span className="col-6" onClick={() => { navigate('/leads') }}>Total</span> <span className="col-6">{leads.length}</span></ListGroup.Item>
                            </ListGroup>
                        </Card>


                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Lead Status - Daily Update</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="justify-content-evenly d-flex"><span className="col-6">Leads Not Contacted</span> <span className="col-6">{dleadNotContacted.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-evenly d-flex"><span className="col-6" style={{ color: '#961406' }}>Follow Up Delayed</span> <span className="col-6" style={{ color: '#961406' }}>{dfollowupDelayed.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">New Leads</span> <span className="col-6">{dnewLeads.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Following Up</span> <span className="col-6">{dfollowingUp.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Converted</span> <span className="col-6">{dcontractSigned.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex"><span className="col-6" style={{ color: 'red' }}>Declined</span> <span className="col-6" style={{ color: 'red' }}>{ddeclined.length}</span></ListGroup.Item>
                                <ListGroup.Item className="justify-content-around d-flex"><span className="col-6">Total</span> <span className="col-6">{dleads.length}</span></ListGroup.Item>
                            </ListGroup>
                        </Card>
                        <Card className="overflow-hidden my-2">
                            {dleads.length > 0 ? <Card.Body>
                                <ProgressBar variant="success" now={dcontractSigned.length / dleads.length * 100} label={`${(dcontractSigned.length / dleads.length * 100).toFixed(2)}% converted`} className="mb-3" />
                                <ProgressBar variant="info" now={dnewLeads.length / dleads.length * 100} label={`${(dnewLeads.length / dleads.length * 100).toFixed(2)}% new Leads`} className="mb-3" />
                                <ProgressBar variant="warning" now={dfollowupDelayed.length / dleads.length * 100} label={`${(dfollowupDelayed.length / dleads.length * 100).toFixed(2)}% delayed`} className="mb-3" />
                                <ProgressBar variant="danger" now={ddeclined.length / dleads.length * 100} label={`${(ddeclined.length / dleads.length * 100).toFixed(2)}% declined`} className="mb-3" />
                                <ProgressBar variant="dark" now={dfollowingUp.length / dleads.length * 100} label={`${(dfollowingUp.length / dleads.length * 100).toFixed(2)}% following`} />
                            </Card.Body> : <Col className="d-flex justify-content-center align-items-center text-muted"><img src={nodata} width={200} /><p>No Data :(</p></Col>}

                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Leads;